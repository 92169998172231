/**=====================
     63. Responsive CSS Start
==========================**/
/* ========= min and max scss for 1200 to 1366 screen ========= */
@media screen and (max-width: 1660px) {
  .vertical-menu-main .mega-menu {
    width: 1200px !important;
    max-width: 1200px !important;
    left: 0 !important; }
  .user-status.emplyee-status tbody tr td:first-child {
    min-width: 284px; }
  .chat-box .chat-history .call-icons ul li {
    border: 1px solid #717171; }
  .chat-box .chat-history .total-time h2 {
    color: #717171; }
  .chat-box .chat-history .call-content .btn {
    width: auto;
    margin: 0 auto; }
  .chat-left-aside .people-list {
    height: 520px; }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 440px; }
  .chat-box .people-list ul {
    max-height: 450px;
    overflow: auto; }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 625px; }
    .call-chat-body .chat-box .people-list ul {
      max-height: 550px;
      overflow: auto; }
  .caller-img {
    position: absolute !important;
    width: 100%;
    max-width: 100%;
    left: 15px; }
    .caller-img img {
      opacity: 0.7; }
  .chat-box .chat-history .receiver-img {
    margin-top: 25px; }
  .chat-box .chat-history .call-content > div {
    height: 545px;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px; } }

@media (max-width: 1280px) {
  .box-layout.page-wrapper .page-body-wrapper {
    width: unset; }
    .box-layout.page-wrapper .page-body-wrapper .footer-fix {
      width: 100%;
      left: unset;
      padding-left: 250px; }
    .box-layout.page-wrapper .page-body-wrapper.sidebar-close .footer-fix {
      padding-left: 0px;
      width: 100%; } }

@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons, .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .vertical-menu-mt .crm-activity {
    height: 298px; }
  .flot-chart-container-small {
    height: 227px; }
  .call-chat-sidebar {
    max-width: 260px; }
  .product-box .product-details {
    padding: 15px; }
    .product-box .product-details p {
      margin-bottom: 10px; }
  .flot-chart-container {
    height: 282px; }
    .flot-chart-container #toggling-series-flot {
      width: 74% !important; }
  .custom-card .card-profile img {
    height: 115px; }
  .custom-card .profile-details h4 {
    font-size: 18px; }
  .custom-card .profile-details h6 {
    margin-bottom: 24px; }
  .custom-card .card-footer > div h3 {
    font-size: 18px; }
  .xl-none {
    display: none; }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%; }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%; }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%; }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%; }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%; }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%; }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%; }
  .crm-activity {
    height: 291px;
    overflow-y: auto; }
  .calender-widget .cal-date {
    width: 95px;
    height: 95px; }
    .calender-widget .cal-date h5 {
      line-height: 1.2;
      padding: 18px; }
  .customer-review .d-flex img {
    width: 50px !important; }
  .customer-review p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px; }
  .whether-widget .whether-widget-top img {
    height: 178px; }
  .whether-widget .whether-widget-top span {
    padding-top: 24px; }
  .chat-body {
    padding: 10px; }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px; }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px; }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px; }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px; }
    .chat-box .chat-history .call-icons ul li {
      width: 50px;
      height: 50px;
      padding: 7px; }
  .chat-box .user-image {
    width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px; }
  .chat-box .about {
    margin-top: 0; }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #aaaaaa !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 376px;
    overflow-y: auto; }
  .chat-box .chat-menu .user-profile .image .avatar img {
    width: 100px;
    height: 100px; }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 13px; }
  .chat-left-aside .people-list {
    height: 420px; }
    .chat-left-aside .people-list ul {
      max-height: 376px;
      overflow-y: auto; }
  .chat-left-aside .status-circle {
    top: 25px;
    left: 22px; }
  .business-card table {
    margin-left: 15px; }
  .business-top-widget .media .media-body {
    margin-right: 30px; }
  .business-top-widget .media .total-value {
    font-size: 24px; }
  .business-top-widget .media i {
    font-size: 55px; }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px 10px; }
  .blog-box .blog-details-main .blog-social li {
    padding: 0px 10px; }
  .blog-bottom-content {
    display: none; }
  .blog-box.blog-shadow .blog-details {
    padding: 10px; }
  .browser-widget img {
    height: 50px; }
  .email-wrap .email-left-aside .email-app-sidebar .media {
    display: block; }
    .email-wrap .email-left-aside .email-app-sidebar .media .media-size-email {
      width: 100%;
      text-align: center; }
      .email-wrap .email-left-aside .email-app-sidebar .media .media-size-email .me-3 {
        margin-right: 0 !important; }
    .email-wrap .email-left-aside .email-app-sidebar .media .media-body {
      text-align: center; }
  .email-wrap .email-right-aside .email-body .attachment ul li img {
    width: 73px;
    height: 73px; }
  .email-wrap .email-right-aside .email-body .email-top {
    line-height: 50px; }
    .email-wrap .email-right-aside .email-body .email-top .float-right {
      display: none !important; }
    .email-wrap .email-right-aside .email-body .email-top h5 {
      padding: 13px 0; }
  .email-wrap .email-right-aside .email-body .inbox {
    max-height: 668px;
    overflow: auto; }
    .email-wrap .email-right-aside .email-body .inbox .media {
      display: block; }
      .email-wrap .email-right-aside .email-body .inbox .media .media-size-email {
        width: 100%;
        text-align: center; }
        .email-wrap .email-right-aside .email-body .inbox .media .media-size-email .me-3 {
          margin-right: 0 !important; }
    .email-wrap .email-right-aside .email-body .inbox .media-body {
      text-align: center; }
      .email-wrap .email-right-aside .email-body .inbox .media-body h6 {
        font-size: 14px; }
  .email-wrap .email-right-aside .email-body .email-compose .cke_contents.cke_reset {
    max-height: 144px;
    width: 99%; }
  .email-wrap .email-right-aside .email-body .email-compose .compose-border {
    margin: 0; }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 181px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important; } }

/* ========= min and max scss for 992 to 768 screen ========= */
@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .landing-main .main-landing {
    padding: 40px 0 0 0px !important; }
    .landing-main .main-landing .link-arrow a {
      left: 261px !important; }
    .landing-main .main-landing .landing-left {
      padding-left: 48px !important; }
      .landing-main .main-landing .landing-left h1 {
        font-size: 27px !important; }
      .landing-main .main-landing .landing-left p {
        font-size: 15px !important; } }

/* ========= responsive scss for 1366 screen ========= */
@media only screen and (max-width: 1366px) {
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 364px; }
    .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
      margin-top: -30px; }
    .chat-box .chat-right-aside .chat .chat-msg-box .message {
      width: 100%;
      padding: 15px; }
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden; }
    .chat-right-aside .chat .chat-message .smiley-box {
      width: 45px; }
  .chat-box .chat-history .receiver-img img {
    height: 150px; }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 565px; }
  .landing-main .main-landing {
    padding: 100px 0 0 0px; }
    .landing-main .main-landing .link-arrow a {
      bottom: 39px;
      left: 366px; }
    .landing-main .main-landing .landing-left {
      margin: 0px 0 0 100px; }
      .landing-main .main-landing .landing-left h1 {
        font-size: 34px; }
      .landing-main .main-landing .landing-left p {
        font-size: 17px; }
  .landing-main .updates-features .inner-header {
    font-size: 18px; }
  .landing-main .exclusive-features {
    background-position: right; }
    .landing-main .exclusive-features .exclusive-image .page-builder-middle {
      width: 36%;
      left: -79px; }
    .landing-main .exclusive-features .exclusive-image .page-builder-top {
      width: 95%;
      top: 59px; }
    .landing-main .exclusive-features .exclusive-image .page-builder-bottom {
      width: 26%;
      bottom: -24px;
      right: 159px; }
    .landing-main .exclusive-features .exclusive-image .form-builder-top {
      top: -31px;
      right: 140px;
      width: 30%; }
    .landing-main .exclusive-features .exclusive-image .form-builder-bottom {
      width: 49%; }
    .landing-main .exclusive-features .exclusive-image .button-builder-left {
      width: 27%;
      bottom: 39px; }
    .landing-main .exclusive-features .exclusive-image .button-builder-middle {
      left: 203px;
      width: 44%; }
    .landing-main .exclusive-features .exclusive-image .fontawesome-right {
      bottom: -34px;
      right: 55px;
      width: 62%; }
  .landing-main .advance-bg .advance-content {
    padding: 30px 10px; }
    .landing-main .advance-bg .advance-content p {
      font-size: 16px; }
  .calender-widget .cal-desc p {
    max-height: 88px;
    overflow: auto; }
  .pricing-wrapper-card .card-price {
    margin: 30px 0; }
  .pricing-wrapper-card .card-description ul {
    margin-top: 30px; }
  .pricing-wrapper-card .pricing-card.popular .card-action button {
    height: 70px; }
  .pricing-wrapper-card .pricing-card.popular .card-price {
    margin: 0; }
  .equal-height-xl {
    height: 414px; }
  .authentication-main .auth-innerleft, .authentication-main .auth-innerright {
    padding: 20px 50px;
    background-size: cover; }
  .pricing-wrapper-card {
    padding: 50px 0px; }
  .grp-btns {
    display: inline-block; }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%; }
  .page-builder .btn-group {
    display: inline-block;
    margin-bottom: 10px; }
  .page-builder .btn-grid {
    margin-bottom: 15px;
    margin-right: 0px;
    border-right: none;
    display: inline-block; }
  .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 50px; }
  .height-scroll {
    max-height: 407px; }
  .business-card .logo {
    width: 90px; }
  .ecommerce-widget .total-num {
    font-size: 23px;
    letter-spacing: 0px; }
  .support-ticket-font .total-num {
    font-size: 22px; }
  .crm-numbers .col {
    padding: 11px; }
  .whether-widget .whether-widget-bottom p {
    font-size: 13px; }
  .whether-widget .whether-widget-bottom svg {
    width: 60px;
    height: 60px; }
  .stat-widget-dashboard .d-flex img {
    height: 30px; }
  .stat-widget-dashboard h3 {
    font-size: 25px; }
  .stat-widget-dashboard h6 {
    font-size: 14px; }
  .default-widget-count h3 {
    font-size: 20px; }
  .default-widget-count p {
    font-size: 15px;
    z-index: 1;
    position: relative; }
  .default-widget-count .left {
    width: 76px;
    height: 76px; }
    .default-widget-count .left i {
      font-size: 35px;
      line-height: 2.3; }
  .default-widget-count .icon-bg {
    font-size: 40px; }
  .default-dashboard-main-chart {
    height: 300px; }
  .testimonial-default .slide--item p {
    max-height: 158px;
    overflow: scroll; }
  .testimonial-default .slide--item .media {
    padding-top: 35px; }
  .custom-card {
    padding: 15px; }
    .custom-card .card-social li a {
      padding: 12px; }
  .map-chart .chart-container {
    height: 300px; }
  .speed-chart .chart-container {
    height: 250px;
    margin-top: -40px;
    margin-bottom: 100px; }
  .candidcahrt {
    height: 360px; }
  .support-ticket .left {
    height: 50px;
    width: 50px;
    font-size: 25px; }
  .support-ticket table td p {
    font-size: 14px; }
  .status-details h3 {
    font-size: 18px; }
  .bar-chart canvas {
    width: 100% !important; }
  .chart-widget-top .total-value {
    font-size: 20px; }
  .status-widget .card-body {
    padding: 10px; }
  .browser-widget .media {
    flex-wrap: wrap; }
    .browser-widget .media .media-body h3 {
      font-size: 20px; }
    .browser-widget .media .media-body p {
      margin-bottom: 2px; }
    .browser-widget .media .media-body h4 {
      font-size: 20px; }
  .testimonial i {
    font-size: 46px; }
  .testimonial p {
    margin-top: 20px; }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    padding: 0;
    width: unset; }
  .company-card {
    padding: 20px; }
  .static-top-widget > div + div h3 {
    font-size: 30px; }
  .static-top-widget > div + div .icon-bg {
    right: -21px;
    font-size: 120px; }
  .widget-joins .d-flex {
    padding: 20px; }
    .widget-joins .d-flex > div h3 {
      font-size: 20px; }
  .redial-social-widget {
    width: 125px;
    height: 125px; }
    .redial-social-widget i {
      width: 100px;
      height: 100px;
      font-size: 45px; }
  .chat-body {
    padding: 10px; }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px; }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px; }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px; }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px; }
    .chat-box .chat-history .call-icons ul li {
      width: 50px;
      height: 50px;
      padding: 7px; }
  .chat-box .user-image {
    width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px; }
  .chat-box .about {
    margin-top: 0; }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #aaaaaa !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 376px;
    overflow-y: auto; }
  .chat-box .chat-menu .user-profile {
    margin-top: 0; }
    .chat-box .chat-menu .user-profile .image .avatar img {
      width: 100px;
      height: 100px; }
    .chat-box .chat-menu .user-profile .image .icon-wrapper {
      width: 30px;
      height: 30px;
      font-size: 13px; }
    .chat-box .chat-menu .user-profile .user-content p {
      font-size: 14px;
      margin-bottom: 10px; }
    .chat-box .chat-menu .user-profile .user-content h5 {
      margin: 20px 0; }
    .chat-box .chat-menu .user-profile .user-content hr {
      margin: 20px 0; }
    .chat-box .chat-menu .user-profile .follow .follow-num {
      font-size: 20px; }
  .chat-left-aside .people-list {
    height: 490px; }
    .chat-left-aside .people-list ul {
      max-height: 434px;
      overflow-y: auto; }
  .chat-left-aside .status-circle {
    top: 25px;
    left: 22px; }
  .mobile-clock-widget #date {
    margin-top: 30px; }
  .mega-menu .onhover-show-div {
    left: 164px; }
  .img-cropper .docs-toggles {
    margin-top: 8px; }
  .bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
    left: -21px; }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 8px; }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 67px !important; }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 67px !important; }
  .reset-password-box .theme-form .form-group .btn {
    padding: 6px 22px; } }

/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .landing-main h4 {
    font-size: 20px; }
  .landing-main .navbar-expand-lg .navbar-brand {
    padding-left: 5px; }
  .landing-main .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item a {
    padding: 9px; }
  .landing-main .main-landing .link-arrow a {
    bottom: 28px;
    left: 325px;
    padding: 9px 14px; }
    .landing-main .main-landing .link-arrow a i {
      font-size: 20px; }
  .landing-main .updates-features .inner-header {
    width: 22%; }
  .landing-main .many-feature-section h5 {
    font-size: 17px; }
  .landing-main .exclusive-features .exclusive-content .inner-block {
    padding-left: 35px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-middle {
    left: -75px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-top {
    top: 53px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-bottom {
    right: 130px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-top {
    top: -27px;
    right: 100px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-bottom {
    bottom: -69px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-left {
    bottom: 34px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-middle {
    left: 182px;
    width: 40%;
    bottom: -40px; }
  .landing-main .exclusive-features .exclusive-image .fontawesome-right {
    bottom: -30px; }
  .landing-main .advance-bg {
    background-position: center left; }
    .landing-main .advance-bg .advance-content {
      padding: 22px 10px; }
      .landing-main .advance-bg .advance-content p {
        font-size: 14px; }
    .landing-main .advance-bg .large-number .large-number-block p {
      font-size: 16px; }
  .lg-mt-col {
    margin-top: 30px; }
  .xl-none {
    display: none; }
  .user-status table tbody tr td .d-inline-block {
    margin-top: 10px; }
  .user-status table thead tr th {
    border-top: 0;
    font-size: 16px;
    color: #2a3142;
    font-weight: 600;
    padding-top: 0; }
  .lg-mt {
    margin-top: 30px; }
  .business-card .b-l-light {
    border-left: none !important; }
    .business-card .b-l-light table {
      width: 60%;
      margin: 20px auto 0 auto; }
  .product-page-details {
    margin-top: 30px; }
  .pricing-wrapper-card {
    padding: 0; }
    .pricing-wrapper-card .pricing-card {
      margin: 0; }
      .pricing-wrapper-card .pricing-card + .pricing-card {
        margin-top: 30px;
        margin-bottom: 0; }
    .pricing-wrapper-card .pricing-card {
      flex: unset; }
  .debit-card {
    order: 1; }
  .typography .row div + div {
    margin-top: 30px; }
  .listing .card-body .row > div + div {
    margin-top: 30px; }
  .flot-chart-container {
    height: 250px; }
  .img-cropper #putData {
    margin-bottom: 10px; }
  .img-cropper .docs-toggles > .dropdown {
    margin-bottom: 0; }
  .error-wrapper .maintenance-icons li i {
    color: #eeeeee;
    font-size: 40px; }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 80px;
    margin-left: 90px; }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 120px;
    margin-top: -75px;
    margin-right: 110px; }
  div.dataTables_wrapper div.dataTables_paginate ~ .dataTables_filter {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0; }
  .jvector-map-height {
    height: 280px; }
  .blog-list .blog-details p {
    margin-bottom: 5px; }
  .custom-card .card-profile img {
    height: 210px; }
  .custom-card .card-profile .card-footer > div h3 {
    font-size: 18px; }
  .testimonial-default .slide--item p {
    max-height: 66px;
    overflow: auto; }
  .testimonial-default .slide--item .media {
    padding-top: 10px; }
    .testimonial-default .slide--item .media img {
      width: 44px !important; }
    .testimonial-default .slide--item .media .media-body h6 {
      font-size: 14px; }
  .equal-height-lg {
    min-height: auto !important; }
  .mega-menu .onhover-show-div {
    height: 435px;
    overflow-x: scroll;
    left: 215px; }
  .mega-menu .mega-bg {
    display: none; }
  .mega-menu .lg-mt {
    margin-top: 25px; }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%; }
  .blog-single .comment-box ul .comment-social {
    margin-left: 0; }
  .error-wrapper .maintenance-heading {
    margin-top: 30px; }
    .error-wrapper .maintenance-heading .headline {
      font-size: 50px;
      margin-top: -15%;
      margin-bottom: 0px; }
    .error-wrapper .maintenance-heading .cloud-second {
      margin-top: -25px; }
  .business-card {
    padding-left: 30px !important; }
  .review-box {
    padding: 10px; }
  .contact-form {
    padding: 10px; }
    .contact-form .theme-form .form-icon {
      margin-top: -57px; }
  .crm-social i {
    font-size: 40px; }
  .crm-social h3 {
    font-size: 25px; }
  .calender-widget .cal-date {
    width: 90px;
    height: 90px; }
    .calender-widget .cal-date h5 {
      font-size: 17px;
      padding: 18px; }
  .calender-widget .cal-desc {
    padding: 14px; }
    .calender-widget .cal-desc p {
      max-height: 114px;
      overflow-y: auto; }
  .border-widgets > .row > div:nth-child(1) {
    border-bottom: 1px solid #ddd; }
  .border-widgets > .row > div:nth-child(2) {
    border-bottom: 1px solid #ddd; }
  .border-widgets > .row > div:nth-child(3) {
    border-left: none; }
  .whether-widget .whether-widget-bottom svg {
    width: 100px;
    height: 100px; }
  .testimonial p {
    margin-top: 17px; }
  .user-status.product-chart {
    max-height: 399px;
    overflow: auto; }
  .browser-widget {
    padding: 0px; }
    .browser-widget .media .media-body h4 {
      font-size: 17px; }
    .browser-widget .media .media-img {
      width: 100%;
      text-align: center;
      margin-bottom: 10px; }
  .crm-numbers .col {
    padding: 0;
    border-bottom: none; }
    .crm-numbers .col + .col {
      border-left: 1px solid #eeeeee; }
      .crm-numbers .col + .col + .col {
        border-bottom: none;
        padding-bottom: 0;
        border-left: 1px solid #eeeeee; }
  .crm-overall .overall-chart {
    height: 200px; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 314px;
    overflow-y: auto; }
  .chat-box .btn-lg {
    font-size: 12px; }
  .chat-box .people-list ul {
    height: auto; }
  .chat-box .chat-right-aside .chat .chat-header {
    height: auto; }
    .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
      font-size: 19px; }
  .chat-box .chat-right-aside .chat .chat-msg-box .message {
    width: 100%; }
  .chat-box .chat-right-aside .chat .chat-message {
    margin: 0; }
  .chat-box .chat-history .total-time h2 {
    font-size: 25px; }
  .chat-box .chat-history .row .col-sm-7 {
    padding-right: 0; }
  .chat-box .border-tab.nav-tabs {
    width: 100%; }
    .chat-box .border-tab.nav-tabs .nav-item {
      margin-right: 5px; }
  .chat-box .chat-left-aside .people-list {
    height: 493px; }
  .chat-menu-icons {
    display: block;
    clear: both;
    width: 100%;
    text-align: right; }
  .chat-menu {
    right: 0;
    border-top: 1px solid #ddd;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute !important;
    z-index: 9;
    background-color: #fff;
    transition: all linear 0.3s; }
    .chat-menu.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px; }
  .chat-history {
    height: 447px; }
  .chat-box .chat-history .call-content > div {
    padding-top: 70px; }
  .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important; }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden; }
  .call-chat-body .chat-box {
    overflow: auto;
    height: auto; }
  .email-wrap .email-right-aside .email-body {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    .email-wrap .email-right-aside .email-body .b-r-light {
      border-right: none !important; }
  .email-wrap .email-right-aside .radius-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 15px; }
  .email-wrap .row .col-xl-6 {
    padding-left: 15px; }
  .icon-lists div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 209px; }
  .like-comment-lg-mb {
    margin-bottom: 12px; }
  .ribbon-space-bottom {
    top: 9px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 98.5% !important; }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 95px !important; }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 95px !important; }
  .profile-img-style #aniimated-thumbnials-3 {
    text-align: center; }
  .vertical-menu-mt {
    margin-top: 80px !important; }
    .vertical-menu-mt .xl-none {
      display: block; }
  .sm {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eeeeee;
    top: 80px;
    height: 100vh;
    left: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll; }
  .calendar-wrap .fc-day-grid-event {
    width: 87%; } }

/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .box-layout.page-wrapper .page-body-wrapper .footer-fix {
    padding-left: 0px; }
  .box-layout.page-wrapper .page-body-wrapper.sidebar-hover .page-body {
    margin-left: 0 !important; }
  .box-layout.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar {
    display: block !important; }
  .sm {
    top: 60px; }
  .fixed-nav-layout .page-body-wrapper.sidebar-icon .page-sidebar {
    left: 0; }
  .landing-main .navbar-expand-lg .navbar-collapse {
    position: absolute;
    top: 90px;
    background-color: white;
    width: calc(100% - 30px);
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 15px; }
  .page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar ~ .page-body {
    margin-left: 0; }
  .page-wrapper .page-body-wrapper.sidebar-icon.sidebar-close .page-sidebar {
    left: -150px; }
  .page-body-wrapper.sidebar-hover .page-body #customer-review .owl-stage-outer {
    width: 100% !important; }
  .page-body-wrapper.sidebar-hover .page-body .calender-widget .cal-desc p {
    max-height: 101px; }
  .gallery {
    margin-bottom: -20px; }
  .my-gallery figure {
    margin-bottom: 20px; }
  #aniimated-thumbnials figure:nth-child(12), #aniimated-thumbnials figure:nth-child(11), #aniimated-thumbnials figure:nth-child(10), #aniimated-thumbnials figure:nth-child(9) {
    margin-bottom: 20px; }
  .page-wrapper .page-main-header .main-header-right .nav-right .notification {
    top: 10px; }
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    height: 50px; }
  .landing-main .advance-widget .support-section {
    margin-top: 18px; }
  .landing-main .advance-widget .widgets-section {
    height: 500px; }
  .landing-main .section-space p {
    font-size: 15px; }
  .landing-main .particles-headline {
    background-image: linear-gradient(#44d6e9 1%, #a888eb 100%);
    padding-bottom: 70px; }
  .landing-main .navbar-expand-lg .navbar-toggler {
    background-color: #fff; }
    .landing-main .navbar-expand-lg .navbar-toggler .navbar-toggler-icon i {
      margin-top: 5px;
      color: #ab8ce4; }
  .landing-main .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
    z-index: 111; }
    .landing-main .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item a {
      color: #000; }
  .landing-main .landing-header h2 {
    margin-bottom: 20px; }
  .landing-main .main-landing .link-arrow a {
    bottom: -47px;
    left: 275px !important; }
  .landing-main .main-landing .landing-left {
    margin: 0 0 0 0; }
  .landing-main .upcoming-section .demo-page-name {
    margin-bottom: 60px; }
  .landing-main .updates-features .inner-header {
    width: 30%;
    margin-bottom: 60px; }
  .landing-main .key-features-section .feature-main .feature-content {
    margin-top: 40px; }
    .landing-main .key-features-section .feature-main .feature-content h5 {
      font-size: 20px; }
  .landing-main .admin-options .live-demo-btn {
    margin-bottom: 60px; }
    .landing-main .admin-options .live-demo-btn h4 {
      font-size: 20px; }
  .landing-main .many-feature-section .mt-md-many-feature {
    margin-top: 20px; }
  .landing-main .exclusive-features .exclusive-content .inner-block {
    margin-left: 35px; }
  .landing-main .exclusive-features .exclusive-content p {
    width: 94%; }
  .landing-main .exclusive-features .exclusive-content h2 {
    font-size: 24px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-middle {
    left: -60px;
    bottom: 35px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-top {
    top: 45px;
    left: -44px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-bottom {
    bottom: -18px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-top {
    top: -21px;
    right: 85px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-bottom {
    bottom: -59px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-left {
    width: 29%;
    bottom: 25px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-middle {
    left: 150px;
    bottom: -34px; }
  .landing-main .exclusive-features .exclusive-image .fontawesome-right {
    bottom: -26px; }
  .landing-main .section-space {
    padding: 60px 0; }
  .landing-main .advance-bg .advance-content {
    padding: 12px 10px; }
    .landing-main .advance-bg .advance-content p {
      font-size: 13px; }
  .landing-main .advance-bg .large-number h4 span {
    font-size: 45px; }
  .landing-main .advance-bg .large-number .large-number-block p {
    font-size: 16px; }
  .landing-main .application-section .landing-header p {
    margin-bottom: 60px; }
  .landing-main .landing-components p {
    font-size: 14px; }
  .landing-main .footer-bg h2 {
    font-size: 28px; }
  .landing-main .footer-bg .star-rate i {
    font-size: 40px; }
  .landing-main .support-section p {
    font-size: 13px; }
  .helper-classes {
    padding: 20px; }
  .floated-customizer-btn, .floated-customizer-panel {
    display: none; }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 20px; }
  .weather-widget-two .bottom-whetherinfo .whether-content {
    top: 25px; }
  .social-widget-card .b-b-light {
    padding: 20px;
    margin-bottom: 20px; }
  .social-media-counter i {
    font-size: 45px; }
  .ecommerce-widget .progress-showcase {
    margin-top: 20px; }
  .crm-numbers {
    padding-bottom: 20px; }
  .crm-overall {
    margin: 0 -20px -20px; }
  .pricing-wrapper-card .pricing-card {
    margin: 0 15PX; }
  .product-page-main {
    padding: 20px; }
  .tabbed-card ul {
    padding: 18px 15px; }
  .tabbed-card ul {
    top: -5px; }
    .tabbed-card ul.border-tab.nav-tabs {
      padding: 0; }
      .tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
        padding: 18px 15px; }
  .tilt-showcase .tilt-image {
    text-align: center; }
  .tilt-showcase .mt-4 {
    margin-top: 20px !important; }
  .todo .todo-list-wrapper .mark-all-tasks {
    top: 20px;
    right: 20px; }
  .custom-card {
    padding-bottom: 0; }
    .custom-card .card-footer {
      padding: 0 15px 15px 15px !important; }
    .custom-card .card-header {
      border: none; }
    .custom-card .dashboard-card {
      padding: 0; }
    .custom-card .card-profile img {
      height: 155px; }
  .display-1 {
    font-size: 4rem; }
  .display-2 {
    font-size: 3.5rem; }
  .display-3 {
    font-size: 2.5rem; }
  .display-4 {
    font-size: 1.5rem; }
  .typography .h1 {
    font-size: 34px; }
  .typography .h2 {
    font-size: 30px; }
  .typography .h3 {
    font-size: 26px; }
  .typography .h4 {
    font-size: 22px; }
  .typography .h5 {
    font-size: 18px; }
  .typography .h6 {
    font-size: 15px; }
  h1 {
    font-size: 34px; }
  h2 {
    font-size: 28px; }
  h3 {
    font-size: 26px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 18px; }
  h6 {
    font-size: 15px; }
  .flot-chart-container {
    height: 300px; }
  .card-absolute .card-header {
    padding: 10px 15px !important; }
  .comingsoon .comingsoon-inner .countdown .time {
    width: 80px;
    height: 80px;
    font-size: 26px; }
  footer {
    bottom: -20px; }
  .authentication-main .auth-innerleft, .authentication-main .auth-innerright {
    padding: 50px; }
  .authentication-main .auth-innerleft {
    background-size: cover; }
  .calendar-wrap .basic-calendar .external-events .fc-event + .fc-event {
    margin-top: 10px; }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-bottom: -10px;
    margin-top: 15px; }
  .card .card-header .card-header-right {
    top: 13px; }
  .page-wrapper .page-main-header {
    height: 60px; }
    .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
      height: 50px; }
    .page-wrapper .page-main-header .main-header-right .nav-right {
      position: unset; }
      .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle {
        cursor: pointer; }
        .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle .icon-more {
          vertical-align: middle; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul {
        top: 60px;
        position: absolute;
        z-index: -1;
        background-color: #fff;
        transition: all linear 0.3s;
        box-shadow: 0 2px 2px 2px #efefef;
        width: 100%;
        left: 0;
        padding: 0 40px;
        transform: translateY(-35px) scaleY(0);
        opacity: 0;
        visibility: hidden; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul.open {
          z-index: 1;
          opacity: 1;
          transform: translateY(0px) scaleY(1);
          visibility: visible; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
          padding: 15px 0; }
          .page-wrapper .page-main-header .main-header-right .nav-right > ul > li h6 {
            font-size: 0; }
          .page-wrapper .page-main-header .main-header-right .nav-right > ul > li .onhover-show-div {
            top: 55px; }
  .page-wrapper .page-body-wrapper.document-content .page-sidebar {
    top: 100px; }
  .page-wrapper .page-body-wrapper .page-sidebar {
    width: 0%;
    top: 60px;
    height: calc(100vh - 60px); }
    .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
      margin-left: 0px;
      padding-top: 0 !important; }
  .page-wrapper .page-body-wrapper .page-sidebar-open {
    width: 250px; }
  .page-wrapper .page-body-wrapper .page-header {
    padding-top: 25px;
    padding-bottom: 25px; }
    .page-wrapper .page-body-wrapper .page-header .row h3 {
      font-size: 22px; }
    .page-wrapper .page-body-wrapper .page-header .breadcrumb {
      float: left;
      padding-left: 0;
      margin-top: 7px; }
  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 60px;
    padding: 0 10px; }
  .page-wrapper .page-body-wrapper .default-according .card .card-header, .page-wrapper .page-body-wrapper .default-according .card .card-body {
    padding: 0.75rem 1.25rem; }
  .page-wrapper .page-body-wrapper .card .card-header, .page-wrapper .page-body-wrapper .card .card-body, .page-wrapper .page-body-wrapper .card .card-footer {
    padding: 20px; }
    .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-t-30 {
      margin-top: 20px !important; }
    .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-b-30 {
      margin-bottom: 20px !important; }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style {
    padding: 20px; }
    .page-wrapper .page-body-wrapper .user-profile .profile-img-style .img-container {
      margin-top: 20px; }
  .page-wrapper .page-body-wrapper .user-profile hr {
    margin: 20px 0; }
  .page-wrapper .page-body-wrapper .user-profile .like-comment {
    margin-top: 20px; }
  .page-builder .ge-addRowGroup {
    width: 100%; }
  .user-profile .hovercard .info .user-designation {
    margin-bottom: 20px; }
  .blog-single .comment-box .comment-social li {
    padding-left: 15px; }
  .error-wrapper {
    padding: 35px 0; }
    .error-wrapper .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px; }
    .error-wrapper .error-heading {
      margin-top: 30px; }
      .error-wrapper .error-heading .cloud-first {
        width: 100%; }
      .error-wrapper .error-heading .cloud-second {
        width: 75%;
        margin-top: -80px; }
      .error-wrapper .error-heading .headline {
        font-size: 180px;
        margin-top: -30%; }
    .error-wrapper .maintenance-heading {
      margin-top: 30px; }
      .error-wrapper .maintenance-heading .cloud-first {
        width: 100%; }
      .error-wrapper .maintenance-heading .cloud-second {
        width: 70%;
        margin-top: -25px; }
  .search-page .info-block {
    flex-wrap: wrap; }
  .search-page .tab-content .pb-4 {
    padding-bottom: 20px !important; }
  .comingsoon .comingsoon-inner img {
    width: 150px; }
  .crm-numbers .col + .col {
    border-left: 1px solid #eeeeee; }
  .review-box {
    padding: 0px; }
  .business-chart-widget .icon-bg {
    right: -42px; }
  .calender-widget .cal-desc {
    padding: 10px 20px 20px !important; }
    .calender-widget .cal-desc p {
      max-height: 100px; }
  .business-card {
    padding-left: 20px !important; }
  .contact-form {
    padding-top: 10px !important; }
    .contact-form .theme-form {
      padding: 20px;
      margin-top: 27px; }
      .contact-form .theme-form .form-icon {
        margin-top: -47px; }
  .crm-top-widget .d-flex > div h3 {
    font-size: 16px; }
  .whether-widget-bottom svg {
    width: 80px;
    height: 80px; }
  .user-status.product-chart {
    max-height: 418px;
    overflow: auto; }
  .widget-joins .d-flex > div i {
    float: none !important; }
  .xl-none {
    display: block; }
  .top-radius-blog {
    width: 100%; }
  .sm-100-w {
    width: 100%;
    height: 100%; }
  .page-wrapper .page-body-wrapper .footer-fix {
    margin-left: 0px;
    padding-right: 0px; }
  /*Form Wizard One start*/
  .form-wizard .btn-mb {
    margin-bottom: 20px; }
  /*Form Wizard One ends*/
  /*Form Wizard Two Start*/
  .stepwizard {
    margin-bottom: 20px; }
  /*Form Wizard Two Ends*/
  /*Form Wizard Three Start*/
  .f1 .f1-steps {
    margin-top: 20px;
    margin-bottom: 20px; }
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  .wizard-4 ul.anchor {
    padding-right: 20px; }
  /*Form Wizard Four ends*/
  .border-tab.nav-tabs {
    margin-bottom: 20px; }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 20px; }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px; }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 20px !important; }
  .search-page .info-block + .info-block {
    padding-top: 20px;
    margin-top: 20px; }
  .search-page #video-links .embed-responsive
+ .embed-responsive {
    margin-top: 20px; }
  .lg-mt {
    margin-top: 20px; }
  .gallery-with-description {
    margin-bottom: -20px; }
    .gallery-with-description a > div {
      margin-bottom: 20px; }
  .clipboaard-container p {
    margin-bottom: 8px; }
  .m-b-30 {
    margin-bottom: 20px !important; }
  .email-wrap .email-left-aside .email-app-sidebar ul li hr {
    margin: 13px 0; }
  .nav-md-mt {
    margin-top: 20px; }
  .navs-icon {
    padding: 20px; }
    .navs-icon .main-section {
      padding-top: 20px; }
    .navs-icon .separator {
      margin: 20px 0; }
  .nav-list {
    padding: 20px; }
  .navs-dropdown button {
    margin-top: 20px; }
  .navs-dropdown .onhover-show-div {
    top: 68px; }
  .animated-modal {
    display: block !important; }
    .animated-modal .form-group {
      width: 100%;
      display: block; }
    .animated-modal .me-4 {
      margin-right: 0 !important; }
    .animated-modal .animated-modal-md-mb {
      margin-bottom: 20px !important; }
  .steps-md-mt {
    margin-top: 20px; }
  .btn-group-wrapper .m-b-30:last-child {
    margin-bottom: 0 !important; }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 20px; }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 20px; }
  .authentication-main .auth-innerleft hr {
    width: 225px; }
  .pricing-wrapper-card .pricing-card {
    padding: 20px; }
    .pricing-wrapper-card .pricing-card + .pricing-card {
      margin-top: 20px; }
  .chat-box .chat-left-aside .people-list {
    height: 483px; } }

/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {
  .blog-box .blog-details-main .blog-social li {
    padding: 0 12px; }
  .landing-main .exclusive-image {
    display: none; }
  .landing-main .landing-home {
    min-height: auto; }
    .landing-main .landing-home .landing-body {
      height: auto;
      padding-top: 20px;
      padding-bottom: 40px; }
  .landing-main .exclusive-features .container > .row .inner-block {
    background-color: #f3f3f3; }
  .page-wrapper .page-body-wrapper.sidebar-hover .page-body {
    margin-left: 0 !important; }
    .page-wrapper .page-body-wrapper.sidebar-hover .page-body .calender-widget .cal-desc p {
      max-height: 100% !important; }
  .my-gallery figure:nth-child(9), .my-gallery figure:nth-child(10) {
    margin-bottom: 20px; }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    font-size: 0; }
    .chat-box .chat-right-aside .chat .chat-message .text-box .btn:before {
      position: absolute;
      content: "\e661";
      font-family: themify;
      font-size: 18px;
      top: 11px;
      left: 20px; }
  .chat-box .border-tab.nav-tabs {
    display: flex; }
  .chat-box .chat-menu .nav-tabs .nav-item a {
    height: 50px; }
  .chat-box .chat-left-aside .people-list {
    height: auto; }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto; }
    .call-chat-sidebar .people-list {
      height: auto; }
  .chat-left-aside .people-list ul {
    max-height: 100px; }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important; }
    .clockpicker-align-top .arrow {
      display: none; }
  .form-builder-column .form-body {
    min-height: 250px; }
  .form-builder-2-header {
    flex-wrap: wrap; }
    .form-builder-2-header > div {
      width: 100%;
      margin-top: 10px; }
      .form-builder-2-header > div nav {
        float: none; }
  .lg-mt-col {
    margin-top: 20px; }
  .form-builder .drag-box fieldset {
    padding: 20px;
    margin-top: 20px; }
  .form-builder #components {
    margin-top: 20px; }
  .form-builder .component label {
    padding-top: 0px; }
  .mega-menu .onhover-show-div {
    height: 415px;
    left: 0;
    top: 61px;
    padding: 20px; }
  .mega-menu .lg-mt {
    margin-top: 15px; }
  .landing-main .landing-home .landing-left h1, .landing-main .landing-home .landing-left h5, .landing-main .landing-home .landing-left p {
    color: #fff; }
  .landing-main .landing-home .landing-left .txt-danger,
  .landing-main .landing-home .landing-left .txt-primary,
  .landing-main .landing-home .landing-left .txt-info {
    color: #fff !important; }
  .landing-main .landing-home .landing-left .pr-5 {
    padding-right: 0 !important; }
  .landing-main .dashboard-image-pt-xs {
    padding-bottom: 30px; }
  .landing-main #pre-build h6.mb-0 {
    margin-bottom: 20px !important; }
  .landing-main .advance-widget .widgets-section {
    height: 400px; }
  .landing-main .landing-header .line {
    margin-bottom: 40px; }
  .landing-main .section-space {
    padding: 40px 0; }
  .landing-main .admin-options .live-demo-btn {
    margin-bottom: 45px; }
  .landing-main .main-landing {
    padding: 27px 0 0 0px; }
    .landing-main .main-landing .link-arrow a {
      bottom: -55px;
      left: 144px !important; }
    .landing-main .main-landing .landing-left {
      margin-left: 0;
      padding-left: 25px; }
      .landing-main .main-landing .landing-left .button-bottom a {
        padding: 12px 26px; }
      .landing-main .main-landing .landing-left h1 {
        font-size: 24px; }
      .landing-main .main-landing .landing-left p {
        font-size: 14px; }
  .landing-main .large-number .large-number-block h4 span {
    font-size: 40px; }
  .landing-main .live-box-sm-mt {
    margin-top: 45px; }
  .landing-main .components-mt {
    margin-top: 30px; }
  .landing-main .upcoming-section .demo-page-name {
    margin-bottom: 40px; }
  .landing-main .key-features-section .feature-main .feature-content {
    margin-top: 30px; }
    .landing-main .key-features-section .feature-main .feature-content h5 {
      font-size: 19px; }
  .landing-main .key-features-section .md-pt-feature {
    padding-top: 30px !important; }
  .landing-main .exclusive-features .exclusive-content .inner-block {
    margin-left: 0; }
  .landing-main .exclusive-features .exclusive-content p {
    width: 90%; }
  .landing-main .updates-features .inner-header {
    width: 35%;
    margin-bottom: 40px; }
  .landing-main .updates-features .updates-xs-mb {
    margin-bottom: 30px; }
  .landing-main .feature-md-mt {
    margin-top: 20px; }
  .landing-main .support-section p {
    margin-bottom: 20px; }
  .landing-main .support-section h6 {
    margin-top: 30px; }
  .landing-main .support-section i {
    font-size: 37px;
    padding: 15px; }
  .landing-main .application-section .nav-pills .nav-item .nav-link {
    padding: 12px 20px; }
  .landing-main .landing-home .landing-body {
    display: flex;
    align-items: center;
    text-align: center; }
    .landing-main .landing-home .landing-body img {
      width: 52%; }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px 0 0 10px; }
    .page-builder .ge-canvas.ge-editing .ge-tools-drawer > a {
      padding: 0 5px; }
  .reader {
    margin-top: 15px;
    height: auto !important; }
  .range-slider.theme-form .form-group {
    margin-bottom: 0; }
  .note-editor .btn-group .btn {
    font-size: 11px; }
  .support-ticket table tbody tr td + td {
    min-width: 110px; }
    .support-ticket table tbody tr td + td + td {
      min-width: 338px;
      overflow: auto; }
  .user-status tbody tr td {
    min-width: 284px; }
    .user-status tbody tr td + td {
      min-width: auto; }
      .user-status tbody tr td + td + td {
        min-width: 100px; }
  .text-md-right {
    text-align: right; }
  .star-ratings .stars {
    padding: 0; }
  .form-inline.d-inline-block {
    display: block !important;
    width: 100%; }
    .form-inline.d-inline-block .form-control {
      margin-bottom: 10px; }
  .reset-password-box {
    width: 500px;
    margin: 0 auto; }
    .reset-password-box .card {
      padding: 20px; }
  .custom-card .card-profile img {
    height: 136px;
    bottom: 0; }
  .error-wrapper .maintenance-icons li i {
    color: #eeeeee;
    font-size: 30px; }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 60px;
    margin-top: -10px;
    margin-left: 70px; }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 90px;
    margin-right: 80px; }
  .authentication-main .auth-innerleft, .authentication-main .auth-innerright {
    min-height: auto;
    padding: 30px 15px; }
    .authentication-main .auth-innerleft .social-media li, .authentication-main .auth-innerright .social-media li {
      font-size: 14px; }
  .loader-box {
    justify-content: center; }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px; }
  .dataTables_wrapper .dataTables_filter {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px; }
  div.dataTables_wrapper div.dataTables_info {
    padding-top: 0; }
  div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
    float: none; }
  div.dataTables_wrapper div.dataTables_paginate {
    float: none;
    margin-top: 20px !important;
    margin-left: 0 !important; }
  .calendar-wrap .fc-basic-view .fc-day-number {
    padding: 0 0px !important; }
  .calendar-wrap .fc-events-container {
    margin-top: 0px; }
  .calendar-wrap .fc-events-container h6, .calendar-wrap .fc-events-container .checkbox {
    text-align: right; }
  .calendar-wrap .checkbox label {
    margin-top: 0; }
  .calendar-wrap .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after {
    font-size: 12px !important; }
  .calendar-wrap .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after {
    font-size: 12px !important; }
  .calendar-wrap .fc-toolbar .fc-left .fc-today-button, .calendar-wrap .fc-toolbar .fc-right .fc-today-button {
    padding-top: 0px !important; }
  .calendar-wrap .fc {
    margin-top: 0px; }
    .calendar-wrap .fc button {
      font-size: 14px !important;
      padding: 0 5px !important; }
    .calendar-wrap .fc .fc-left {
      margin-bottom: 10px; }
    .calendar-wrap .fc .fc-toolbar > div {
      display: block !important;
      float: none !important; }
    .calendar-wrap .fc .fc-toolbar > * :first-child {
      font-size: 18px; }
    .calendar-wrap .fc .fc-toolbar > * > * {
      float: none !important; }
  .btn-group-wrapper {
    text-align: center; }
    .btn-group-wrapper .m-b-30 {
      margin-bottom: 15px !important; }
  .btn-group-showcase {
    text-align: center; }
    .btn-group-showcase .btn-group {
      margin-right: 0;
      margin-bottom: 15px; }
  .btn-group .btn {
    font-size: 14px;
    padding: 0.375rem 1.2rem; }
  .animate-widget .text-center {
    display: none; }
  .business-card .b-l-light table {
    margin: 0 auto 0 auto; }
  .business-top-widget .media .total-value {
    font-size: 25px; }
  .business-chart-widget .total-num {
    font-size: 24px; }
  .business-chart-widget .icon-bg {
    font-size: 95px;
    top: -30px;
    right: -27px; }
  .business-chart-widget .flot-chart-container {
    height: 75px; }
  .social-widget .d-flex {
    padding: 5px 10px; }
  .calender-widget .cal-desc p {
    max-height: 100%; }
  .mega-menu .onhover-show-div {
    height: 350px; }
  .page-builder .ge-canvas .column {
    padding: 0 !important; }
  .page-builder .ge-content-type-ckeditor h3 {
    font-size: 20px; }
  .page-builder .ge-content-type-ckeditor p {
    font-size: 12px; }
  .page-builder .ge-row-icon {
    display: none !important; }
  /* font-awesom icon page */
  .icon-hover-bottom .icon-popup {
    display: block; }
    .icon-hover-bottom .icon-popup > div {
      display: block; }
    .icon-hover-bottom .icon-popup .icon-first, .icon-hover-bottom .icon-popup .icon-class {
      display: inline-block; }
    .icon-hover-bottom .icon-popup .icon-last {
      margin-top: 15px; }
  .icon-hover-bottom .form-group input {
    min-width: 270px; }
  .user-profile .hovercard .cardheader {
    height: 350px; }
  .user-profile .hovercard .info {
    padding: 35px; }
    .user-profile .hovercard .info .ttl-info {
      margin-bottom: 20px; }
    .user-profile .hovercard .info .ttl-sm-mb-0 {
      margin-bottom: 0; }
  .calendar-wrap .fc button {
    padding: 5px; }
  .calendar-wrap .fc-day-grid-event {
    margin: 0;
    padding: 5px;
    width: inherit; }
  .calendar-wrap .fc-toolbar h2 {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold; }
  .calendar-wrap .basic-calendar .external-events {
    margin-top: 0px; }
  .blog-single .comment-box .media h6 {
    margin-bottom: 10px; }
  .blog-single .comment-box .media img {
    margin-right: 30px; }
  .blog-single .comment-box .comment-social {
    margin-bottom: 5px; }
    .blog-single .comment-box .comment-social li:first-child {
      padding-left: 0;
      padding-right: 15px; }
  .border-tab.nav-tabs {
    display: inline-block;
    width: 100%; }
    .border-tab.nav-tabs .nav-item {
      float: left; }
  .tabbed-card .border-tab.nav-tabs {
    display: flex;
    position: relative; }
  .search-page .search-form input {
    background-position: 95%; }
  .search-page #image-links .info-block.m-t-30 {
    margin-top: 0 !important; }
  .authentication-main .authentication-box {
    width: 100%; }
  .authentication-main .auth-innerleft {
    background-image: none;
    box-shadow: none;
    position: relative;
    width: 100%;
    min-height: auto; }
    .authentication-main .auth-innerleft .security-icon {
      width: 100px; }
  .authentication-main .auth-innerright {
    display: inherit; }
  .border-widgets > .row > div + div {
    border-left: 1px solid #ddd; }
  .crm-top-widget .d-flex i {
    font-size: 40px; }
  .crm-top-widget .d-flex > div h3 {
    font-size: 22px; }
  .user-status.product-chart {
    max-height: 100%; }
  .default-dashboard-main-chart {
    height: 200px; }
  .map-chart .chart-container {
    height: 300px; }
  .candidcahrt {
    height: 250px; }
  .bar-chart-widget .earning-details i {
    right: 0; }
  .serial-chart .chart-container {
    height: 300px; }
  .bottom-content {
    padding: 10px; }
    .bottom-content p {
      font-size: 12px; }
  .stepwizard .stepwizard-row:before {
    top: 17px; }
  .icon-hover-bottom .icon-popup div .flag-icon {
    width: 30px;
    height: auto; }
  .icon-hover-bottom .icon-popup .form-group input {
    min-width: 40%; }
  .footer-links {
    text-align: center; }
  .footer-copyright {
    text-align: center; }
  .jvector-map-height {
    height: 150px; }
  .sm-left-text {
    text-align: left !important; }
  .email-wrap .email-right-aside .email-content .email-top .d-flex {
    padding-bottom: 0; }
  .email-wrap .email-right-aside .email-content .email-top .float-right {
    float: left !important; }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment {
    text-align: center; }
    .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li:last-child {
      padding-right: 0; }
    .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
      width: 140px;
      height: 140px; }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px; }
  .button-group-mb-sm {
    margin-bottom: 5px; }
  .card-block .table-responsive .table tbody tr td span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px; }
  .card-block .table-responsive .progress-content tbody tr td span {
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 50px; }
  .card-block .table-responsive .checkbox-td-width tbody tr td, .card-block .table-responsive .radio-first-col-width tbody tr td {
    min-width: 200px !important; }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 119px !important; }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 119px !important; }
  .jsgrid .jsgrid-pager-container {
    text-align: center; }
  .gmap-header-align h5 {
    padding-top: 0; }
  .gmap-header-align .form-inline {
    margin-top: 10px; }
    .gmap-header-align .form-inline input {
      margin-bottom: 0 !important; }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: auto; }
  .chat-box .chat-menu .nav-tabs .nav-item {
    width: 32.33%; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 433px;
    margin-bottom: 15px; }
  .chat-box .chat-menu #info-profile, .chat-box .chat-menu #info-contact {
    margin-bottom: 15px; }
  .hovercard .info .follow .text-md-right {
    text-align: center; }
  .profile-img-style .like-comment-sm-mb {
    margin-bottom: 20px; }
  .reset-password-box .theme-form .form-group .btn {
    margin-top: 15px !important; }
  .lg-outer .lg-actions .lg-next, .lg-outer .lg-actions .lg-prev {
    top: 48%; } }

/* ========= responsive scss for 575 screen ========= */
@media only screen and (max-width: 575px) {
  .ribbon-clip-right, .ribbon-clip-bottom-right {
    right: -6px;
    font-size: 13px;
    padding: 0 15px; }
    .ribbon-clip-right:before, .ribbon-clip-bottom-right:before {
      right: 0;
      border: 4px solid transparent; }
  .ribbon-clip, .ribbon-clip-bottom {
    left: -6px;
    font-size: 13px;
    padding: 0 15px; }
    .ribbon-clip:before, .ribbon-clip-bottom:before {
      left: -1px;
      border: 4px solid transparent; }
  .chart-widgets-small .chart-container {
    height: 282px !important; }
  .bar-chart-widget .earning-details i {
    right: -30px; }
  .cd-timeline-block .cd-timeline-img {
    left: 5px;
    height: 25px;
    width: 25px; }
    .cd-timeline-block .cd-timeline-img > i {
      font-size: 13px;
      top: 15px;
      left: 16px; }
  .cd-container {
    width: 81%; }
  #cd-timeline::before {
    left: -11px; }
  .widget-joins:before {
    display: none; }
  .widget-joins .ps-0 {
    padding-left: 15px !important; }
  .widget-joins .pe-0 {
    padding-right: 15px !important; }
  .widget-joins .border-after-xs:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 94%;
    background-color: #ddd;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto; }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important; }
  .gallery {
    margin-bottom: -15px; }
  .my-gallery figure {
    margin-bottom: 15px; }
  .my-gallery.gallery-with-description figure:last-child {
    margin-bottom: 20px; }
  .invoice .text-md-end {
    margin-top: 10px; }
  .landing-main .landing-home .landing-body img {
    width: 66%; }
  .landing-main .navbar-expand-lg {
    padding-left: 10px; }
    .landing-main .navbar-expand-lg .navbar-brand {
      padding-left: 0; }
      .landing-main .navbar-expand-lg .navbar-brand img {
        width: 60%; }
  .landing-main h4 {
    font-size: 17px; }
  .landing-main .particles-headline {
    padding-top: 110px;
    padding-bottom: 80px; }
  .landing-main .main-landing {
    padding: 0px 0px 0px 0px; }
    .landing-main .main-landing .landing-left .link-arrow a {
      bottom: -66px;
      left: 145px !important; }
  .landing-main .section-space .landing-header h2 {
    font-size: 20px; }
  .landing-main .section-space .landing-header .line {
    margin-bottom: 40px; }
  .landing-main .admin-options .live-demo-btn h4 {
    font-size: 17px; }
  .landing-main .admin-options .live-box img {
    width: 75%; }
  .landing-main .upcoming-section .live-box img {
    width: 75%; }
  .landing-main .updates-features .landing-header p {
    width: 100%; }
  .landing-main .updates-features .inner-header {
    width: 60%; }
  .landing-main .updates-features .updates-xs-mb {
    margin-bottom: 20px; }
  .landing-main .updates-features h6 {
    margin-top: 15px; }
  .landing-main .key-features-section .feature-main .feature-content h5 {
    font-size: 18px;
    margin-bottom: 15px; }
  .landing-main .key-features-section .xs-mt-feature {
    margin-top: 25px; }
  .landing-main .components-mt {
    margin-top: 15px; }
  .landing-main .exclusive-features .landing-header h2:after {
    width: 232px; }
  .landing-main .exclusive-features .exclusive-content h2 {
    font-size: 20px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-top {
    top: 37px;
    left: 0px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-middle {
    left: -21px;
    bottom: 25px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-bottom {
    bottom: -20px;
    right: 79px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-top {
    top: -20px;
    right: 116px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-bottom {
    bottom: -73px;
    right: -26px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-left {
    left: 5px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-middle {
    left: 174px;
    bottom: -30px;
    width: 37%; }
  .landing-main .exclusive-features .exclusive-image .fontawesome-right {
    bottom: -25px;
    right: 77px;
    width: 59%; }
  .landing-main .exclusive-features .order-xs {
    order: 2; }
  .landing-main .exclusive-mt-xs {
    margin-top: 30px; }
  .landing-main .exclusive-shadow-left, .landing-main .exclusive-shadow-right {
    width: 90%; }
  .landing-main .footer-bg h2 {
    font-size: 21px; }
  .landing-main .footer-bg .star-rate {
    margin: 20px 0; }
    .landing-main .footer-bg .star-rate i {
      font-size: 30px; }
  .landing-main .footer-bg button {
    font-size: 18px; }
  .landing-main #cd-timeline::before {
    left: -23px; }
  .landing-main .cd-timeline-block .cd-timeline-img {
    left: -7px;
    height: 30px;
    width: 30px; }
    .landing-main .cd-timeline-block .cd-timeline-img > i {
      font-size: 15px;
      top: 16px;
      left: 6px; }
  .helper-classes {
    padding: 15px; }
  .form-builder-2-header ul li {
    text-align: left; }
  .form-builder-2-header .form-inline {
    display: block !important;
    width: 100%; }
    .form-builder-2-header .form-inline .form-group {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px; }
    .form-builder-2-header .form-inline button {
      width: 100%;
      text-align: left; }
  .page-builder .btn-grid {
    margin-bottom: 7px;
    padding-right: 5px; }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 15px; }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px; }
  .page-builder .ge-canvas.ge-layout-desktop [class*="col-"] {
    width: inherit !important; }
  .wizard-4 .step-container div.content {
    margin-top: 10px; }
  .nav-tabs .nav-item.show .nav-link {
    border-top: 0;
    border-bottom: 0; }
  .nav-tabs .nav-link {
    border-top: 0;
    border-bottom: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus {
      border-top: 0;
      border-bottom: 0; }
  .border-tab.nav-tabs .nav-item {
    width: 100% !important; }
  .border-tab .weather-widget-two .bottom-whetherinfo .whether-content {
    top: 35px; }
  .border-tab .social-widget-card .b-b-light {
    padding: 15px;
    margin-bottom: 15px; }
  .border-tab .ecommerce-widget .progress-showcase {
    margin-top: 15px; }
  .border-tab .height-scroll {
    max-height: 500px; }
  .border-tab .review-box .testimonial .content {
    padding: 15px; }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none; }
  .border-tab.nav-left .nav-link {
    text-align: center;
    border-left: none; }
  .border-tab.nav-right .nav-link {
    text-align: center;
    border-right: none; }
  .border-tab.nav-tabs {
    margin-bottom: 10px; }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 10px; }
  .nav-pills {
    text-align: center; }
  .crm-overall {
    margin: 0 -15px -15px; }
  .product-page-main {
    padding: 15px; }
    .product-page-main .m-t-15 {
      margin-top: 0 !important; }
      .product-page-main .m-t-15 .btn {
        margin-top: 10px; }
  .tabbed-card ul {
    padding: 10px 0 5px 0;
    position: relative;
    width: 100%;
    left: 0; }
    .tabbed-card ul.border-tab {
      margin-bottom: 15px; }
      .tabbed-card ul.border-tab.nav-tabs {
        display: block; }
        .tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
          padding: 5px 15px 10px; }
  .todo .action-box.large {
    height: 20px;
    width: 20px; }
    .todo .action-box.large .icon {
      font-size: 14px;
      vertical-align: 1px; }
  .todo .todo-list-wrapper #todo-list li .task-container .task-label {
    font-size: 14px; }
  .todo .todo-list-wrapper .mark-all-tasks {
    top: 15px;
    right: 15px; }
  .datetime-picker label {
    text-align: left !important; }
  .redial-chart-block {
    text-align: center; }
  #nav-tabContent {
    margin-top: 15px; }
  .reset-password-box {
    width: 430px; }
  .auth-bg {
    padding: 25px 15px; }
  .auth-bg-effect {
    display: none; }
  .date-picker .theme-form > .row > div:last-child {
    margin-top: 0; }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 15px !important; }
  ul.pagination li:before {
    line-height: 3; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-left: 0 !important; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
    padding-right: 0 !important; }
  div.dataTables_wrapper div.dataTables_length {
    margin-bottom: 15px; }
    div.dataTables_wrapper div.dataTables_length select {
      min-height: 30px; }
  div.dataTables_wrapper table.dataTable th, div.dataTables_wrapper table.dataTable td {
    padding: 0.5rem; }
  div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 15px !important; }
    div.dataTables_wrapper div.dataTables_paginate .paginate_button {
      padding: 1px 7px; }
      div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous {
        font-size: 0;
        padding: 0; }
        div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous:before {
          content: "\e64a";
          font-family: themify;
          font-size: 11px;
          padding: 0 5px; }
      div.dataTables_wrapper div.dataTables_paginate .paginate_button.next {
        font-size: 0;
        padding: 0; }
        div.dataTables_wrapper div.dataTables_paginate .paginate_button.next:before {
          content: "\e649";
          font-family: themify;
          font-size: 11px;
          padding: 0 5px; }
  div.dataTables_wrapper .dataTables_filter {
    margin-bottom: 15px; }
    div.dataTables_wrapper .dataTables_filter input[type="search"] {
      height: 30px; }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style {
    padding: 15px; }
    .page-wrapper .page-body-wrapper .user-profile .profile-img-style .img-container {
      margin-top: 15px; }
  .page-wrapper .page-body-wrapper .user-profile hr {
    margin: 15px 0; }
  .page-wrapper .page-body-wrapper .user-profile .like-comment {
    margin-top: 15px; }
  .jvector-map-height {
    height: 250px; }
  .user-profile .order-sm-0 {
    order: -1; }
  .user-profile .hovercard .info .ttl-info {
    text-align: center !important; }
  .user-profile .hovercard .info .ttl-xs-mt {
    margin-top: 20px; }
  .icon-hover-bottom .form-group {
    display: block; }
    .icon-hover-bottom .form-group input {
      width: 100%; }
    .icon-hover-bottom .form-group .btn {
      margin-top: 15px; }
  .icon-hover-bottom .icon-popup {
    padding: 15px; }
  .icon-hover-bottom svg.climacon {
    height: 50px;
    width: 50px; }
  .blog-box.blog-shadow:before {
    box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6); }
  .blog-box .blog-details p, .blog-box .blog-details .single-blog-content-top {
    margin-top: 15px; }
  .blog-box .blog-details h4 {
    margin-top: 15px; }
  .blog-box .blog-details .blog-social {
    margin-top: 15px; }
    .blog-box .blog-details .blog-social li + li {
      padding-left: 20px; }
      .blog-box .blog-details .blog-social li + li > span {
        display: none; }
    .blog-box .blog-details .blog-social li:first-child {
      margin-bottom: 10px;
      padding-right: 20px; }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px; }
    .comment-box ul ul {
      margin-left: 30px; }
    .comment-box li .media {
      display: block; }
      .comment-box li .media img {
        height: 50px;
        width: 50px;
        padding: 3px;
        margin-bottom: 10px; }
  .card .card-header .card-header-right {
    top: 8px; }
  .ace-editor {
    height: 320px; }
  .gallery > a {
    margin-bottom: 15px;
    text-align: center; }
  .card .card-header h5 {
    font-size: 17px; }
  .theme-form .form-group {
    margin-bottom: 15px; }
  .page-wrapper .page-body-wrapper .default-according .card .card-header, .page-wrapper .page-body-wrapper .default-according .card .card-body, .page-wrapper .page-body-wrapper .default-according .card .card-footer {
    padding: 0.75rem 1.25rem; }
  .page-wrapper .page-body-wrapper .card {
    margin-bottom: 15px; }
    .page-wrapper .page-body-wrapper .card .card-header, .page-wrapper .page-body-wrapper .card .card-body, .page-wrapper .page-body-wrapper .card .card-footer {
      padding: 15px; }
      .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-t-30 {
        margin-top: 15px !important; }
      .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-b-30 {
        margin-bottom: 15px !important; }
  .page-wrapper .page-body-wrapper .page-body {
    padding: 0 !important; }
  .page-wrapper .page-body-wrapper .page-header {
    padding-top: 20px;
    padding-bottom: 20px; }
    .page-wrapper .page-body-wrapper .page-header .row h3 {
      font-size: 20px; }
  .page-wrapper .page-main-header .nav-right > ul .search-form .mobile-search {
    background-image: url(../images/dashboard/search.png);
    height: 15px;
    width: 15px;
    display: inline-block;
    background-size: cover;
    margin: 5px 0 0; }
  .page-wrapper .page-main-header .nav-right > ul .search-form .form-control-plaintext {
    top: 60px;
    position: absolute;
    transition: all linear 0.3s;
    left: 0;
    background-color: #fff;
    transform: translateY(-35px) scaleY(0);
    opacity: 0;
    visibility: hidden;
    width: 180px; }
    .page-wrapper .page-main-header .nav-right > ul .search-form .form-control-plaintext.open {
      transform: translateY(0px) scaleY(1);
      opacity: 1;
      visibility: visible;
      transition: all linear 0.3s; }
  .page-wrapper .page-main-header .nav-right > ul .search-form .form-group {
    margin-bottom: 0; }
  /* alert responsive css start here */
  .alert-primary.inverse {
    background-color: transparent; }
  .alert-secondary.inverse {
    background-color: transparent; }
  .alert-success.inverse {
    background-color: transparent; }
  .alert-danger.inverse {
    background-color: transparent; }
  .alert-warning.inverse {
    background-color: transparent; }
  .alert-info.inverse {
    background-color: transparent; }
  .alert-light.inverse {
    background-color: transparent; }
  .alert-dark.inverse {
    background-color: transparent; }
  .alert .close {
    height: 100%; }
  .business-card {
    padding-left: 15px !important; }
    .business-card .b-l-light table {
      width: 100%; }
  .calender-widget .cal-desc {
    padding: 10px 15px 15px !important; }
    .calender-widget .cal-desc p {
      max-height: 100%; }
  .user-profile hr {
    margin: 15px 0; }
  .user-profile .hovercard .cardheader {
    height: 300px; }
  .user-profile .hovercard .info {
    padding: 20px; }
    .user-profile .hovercard .info .user-designation {
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 15px;
      padding: 15px 0px; }
  .user-profile .hovercard .social-media a {
    margin-right: 0px;
    font-size: 16px; }
  .user-profile .hovercard .follow .follow-num {
    font-size: 20px; }
  .user-profile .profile-img-style .pictures img {
    margin-bottom: 10px; }
  .custom-card .card-footer > div h6 {
    font-size: 14px;
    font-weight: 600; }
  .custom-card .card-footer > div h3 {
    font-size: 24px; }
  .calendar-wrap .fc-toolbar .fc-center {
    margin-top: 10px; }
  .calendar-wrap a.fc-more {
    font-size: .70em; }
  /* megaoption page responsive scss */
  .mega-inline {
    display: block; }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important; }
  .blog-box.blog-shadow .blog-details {
    padding: 15px; }
    .blog-box.blog-shadow .blog-details p {
      margin-bottom: 0; }
    .blog-box.blog-shadow .blog-details h4 {
      margin-bottom: 10px; }
  .blog-box.blog-list .blog-details {
    padding-top: 0; }
  .blog-box .blog-details {
    padding: 20px; }
  .blog-box .blog-date span {
    font-size: 25px; }
  .blog-single .blog-box .blog-details {
    padding: 0; }
  .error-wrapper {
    padding: 20px 0; }
    .error-wrapper .btn {
      margin-top: 15px; }
    .error-wrapper .img-100 {
      width: 50px !important; }
    .error-wrapper .error-heading {
      margin-top: 20px; }
      .error-wrapper .error-heading .cloud-second {
        margin-top: -60px; }
      .error-wrapper .error-heading .headline {
        font-size: 125px;
        margin-top: -38%; }
    .error-wrapper .maintenance-heading .cloud-second {
      display: none; }
    .error-wrapper .maintenance-heading .headline {
      font-size: 25px;
      margin-top: -10%;
      letter-spacing: 2px; }
  .comingsoon .comingsoon-inner .coming-soon-bottom-link {
    margin-top: 20px;
    line-height: 1; }
  .comingsoon .comingsoon-inner h5 {
    margin-bottom: 20px;
    margin-top: 20px; }
  .comingsoon .comingsoon-inner .countdown {
    padding: 20px 0px; }
    .comingsoon .comingsoon-inner .countdown ul li {
      margin: 0 7px; }
    .comingsoon .comingsoon-inner .countdown .title {
      font-size: 12px; }
    .comingsoon .comingsoon-inner .countdown .time {
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      margin: 0 auto; }
  /*Form Wizard One start*/
  .form-wizard .btn-mb {
    margin-bottom: 15px; }
  /*Form Wizard One ends*/
  /*Form Wizard Two Start*/
  .stepwizard {
    margin-bottom: 15px; }
  /*Form Wizard Two Ends*/
  /*Form Wizard Three Start*/
  .f1 .f1-steps {
    margin-top: 15px;
    margin-bottom: 15px; }
  /*Form Wizard Three ends*/
  .wizard-4 ul.anchor {
    width: 100%;
    padding-bottom: 15px;
    padding-right: 0; }
  .wizard-4 .step-container {
    width: 100%; }
  .flot-chart-container {
    height: 300px; }
  .small-chart-widget .chart-container {
    height: 134px; }
  .small-chart-widget.layout-chart-height .chart-container {
    height: auto; }
  .status-widget .text-sm-right {
    text-align: right; }
  .crm-numbers .col h4 {
    font-size: 18px; }
  .crm-numbers .chart-block .chart-overflow {
    width: 100%; }
  .crm-numbers .flot-chart-container {
    height: 300px; }
  .crm-numbers .crm-numbers .col h4 {
    font-size: 18px; }
  .crm-numbers .total-chart {
    height: 200px; }
  .crm-numbers .border-widgets > .row > div + div {
    border-left: 1px solid #ddd; }
  .crm-numbers .whether-widget .whether-widget-bottom svg {
    width: 75px;
    height: 75px; }
  .crm-numbers .widget-arrow .row div:nth-child(even) {
    padding-left: 12px !important;
    padding-right: 2px !important; }
  .crm-numbers .widget-arrow .row div:last-child .media h5 {
    margin-right: 12px; }
  .crm-numbers .cal-date-widget .row .cal-info {
    padding-bottom: 10px; }
  .crm-numbers .support-ticket .left {
    height: 50px;
    width: 50px;
    font-size: 25px; }
  .crm-numbers .speed-chart .chart-container {
    margin-top: 0px;
    margin-bottom: 120px; }
  .crm-numbers .width-50, .crm-numbers .width-100 {
    max-width: unset;
    flex: unset; }
  .crm-numbers .shadow-showcase {
    margin-bottom: 30px; }
  .crm-numbers .dataTables_wrapper .dataTables_length {
    margin-bottom: 15px; }
  .crm-numbers .dataTables_wrapper table.dataTable {
    margin-bottom: 15px !important; }
  .crm-numbers .search-page .info-block + .info-block {
    padding-top: 15px;
    margin-top: 15px; }
  .crm-numbers .search-page #video-links .embed-responsive
+ .embed-responsive {
    margin-top: 15px; }
  .crm-numbers .lg-mt {
    margin-top: 15px; }
  .crm-numbers .gallery-with-description {
    margin-bottom: -15px; }
    .crm-numbers .gallery-with-description a > div {
      margin-bottom: 15px; }
  .crm-numbers .clipboaard-container p {
    margin-bottom: 5px; }
  .crm-numbers .clipboaard-container .mt-3 {
    margin-top: 14px !important; }
  .crm-numbers .m-b-30 {
    margin-bottom: 15px !important; }
  .crm-numbers .note-editor .note-frame {
    margin-bottom: 0 !important; }
  .crm-numbers .email-wrap .email-top {
    padding: 15px; }
  .crm-numbers .email-wrap .btn-mail {
    margin: 15px 0; }
  .crm-numbers .email-wrap .email-left-aside .email-app-sidebar .nav {
    text-align: left; }
  .crm-numbers .email-wrap .email-right-aside .email-content .email-body .inbox .media {
    padding: 15px; }
  .crm-numbers .email-wrap .email-right-aside .email-content .email-wrapper {
    padding: 15px; }
    .crm-numbers .email-wrap .email-right-aside .email-content .email-wrapper p + p {
      margin-bottom: 15px; }
    .crm-numbers .email-wrap .email-right-aside .email-content .email-wrapper h5 {
      margin-bottom: 15px; }
    .crm-numbers .email-wrap .email-right-aside .email-content .email-wrapper .m-b-20 {
      margin-bottom: 15px !important; }
    .crm-numbers .email-wrap .email-right-aside .email-content .email-wrapper hr {
      margin-bottom: 15px; }
    .crm-numbers .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
      width: 100px;
      height: 100px; }
  .crm-numbers .form-builder-column #pills-tabContent .theme-form {
    margin-top: 15px; }
  .crm-numbers .form-builder .navbar-expand-md {
    margin-bottom: 0; }
  .crm-numbers .form-builder .form-builder-column #pills-tabContent .theme-form {
    margin-top: 15px; }
  .crm-numbers .form-builder .form-builder .navbar-expand-md {
    margin-bottom: 0; }
  .crm-numbers .form-builder .form-builder .copy-btn {
    margin-top: -15px; }
  .crm-numbers .form-builder .lg-mt-col {
    margin-top: 15px; }
  .crm-numbers .form-builder .form-builder .drag-box fieldset {
    padding: 15px;
    margin-top: 15px; }
  .crm-numbers .form-builder .form-builder #components {
    margin-top: 15px; }
    .crm-numbers .form-builder .form-builder #components #radioscheckboxes .component .form-group .mb-0 {
      margin-bottom: 8px !important; }
    .crm-numbers .form-builder .form-builder #components #radioscheckboxes .component .form-group .m-checkbox-inline .checkbox label.mb-0 {
      margin-bottom: 0 !important; }
  .mega-menu {
    padding-left: 15px; }
    .mega-menu .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px; }
    .mega-menu .lg-mt {
      margin-top: 10px; }
    .mega-menu .xs-mt {
      margin-top: 15px; }
  .nav-md-mt {
    margin-top: 15px; }
  .navs-icon {
    padding: 15px; }
    .navs-icon .main-section {
      padding-top: 15px; }
    .navs-icon .separator {
      margin: 15px 0; }
  .nav-list {
    padding: 15px; }
  .navs-dropdown button {
    margin-top: 15px; }
  .navs-dropdown .onhover-show-div {
    top: 64px; }
  .lg-mt {
    margin-top: 15px; }
  #aniimated-thumbnials a:last-child img {
    margin-bottom: 15px; }
  .tilt-showcase .mt-4 {
    margin-top: 15px !important; }
  .tilt-showcase ol .m-b-20 {
    margin-bottom: 15px !important; }
  .xs-mt {
    margin-top: 15px; }
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px; }
  .alert-dismissible p {
    max-width: 209px; }
  .animated-modal .form-select {
    width: 50%;
    margin: 0 auto; }
  .animated-modal .animated-modal-md-mb {
    margin-bottom: 15px !important; }
  .steps-md-mt {
    margin-top: 15px; }
  .steps-sizing-sm-mb {
    margin-bottom: 15px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important; }
  #API-chield-row_wrapper #API-chield-row tbody tr td:first-child {
    min-width: 20px; }
  .chart-block .flot-chart-container p#choices {
    width: 100%; }
  .chart-block .flot-chart-container #toggling-series-flot {
    width: 100% !important; }
  .gmap-header-align .form-inline button {
    margin-top: 15px; }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 15px; }
  .chat-box .chat-history .call-content > div {
    padding-top: 60px; }
  .chat-box .chat-left-aside .people-list {
    height: auto; }
  .ecommerce-widget .progress-showcase {
    margin-top: 15px; }
  .reset-password-box .theme-form .form-group .btn {
    margin-top: 13px !important; }
  .gallery-with-description a > div {
    margin-bottom: 15px; }
  .gallery-img-mb-sm {
    margin-bottom: 15px; }
  .page-wrapper.document .landing-main .navbar-expand-lg .navbar-brand {
    width: 150px; }
    .page-wrapper.document .landing-main .navbar-expand-lg .navbar-brand img {
      width: unset; }
  .page-wrapper.document .page-body-wrapper.document-content .page-sidebar {
    top: 80px; } }

/* ========= responsive scss for 480 screen ========= */
@media only screen and (max-width: 480px) {
  .box-layout.page-wrapper .set-col-6, .box-layout.page-wrapper .set-col-3, .box-layout.page-wrapper .set-col-7, .box-layout.page-wrapper .set-col-5 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wizard-4 .action-bar .btn {
    width: 100%; }
    .wizard-4 .action-bar .btn + .btn {
      margin-top: 10px; }
  ul.notification-dropdown.onhover-show-div {
    width: 260px;
    right: -80px; }
    ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
      right: 89px !important; }
  .business-card table {
    margin-left: 0px; }
  .crm-numbers {
    padding-bottom: 15px; }
  .whether-widget .whether-widget-bottom svg {
    width: 70px;
    height: 70px; }
  .crm-activity.equal-height-xl {
    height: auto; }
  .inline-block-sm {
    display: inline-block; }
  .pagination-lg .page-link {
    padding: 0.3rem 0.65rem; }
  .nav-pills li {
    width: 100%; }
  .reset-password-box {
    width: 290px; }
  .icon-hover-bottom .icon-first {
    margin-right: 10px; }
  .icon-hover-bottom .icon-popup .icon-class {
    display: none; }
  .error-wrapper .maintenance-heading {
    margin-top: 0; }
  .card .card-header > span + span {
    display: none; }
  .custom-card {
    padding: 0; }
    .custom-card .card-footer > div h3 {
      font-size: 20px; }
    .custom-card .card-social li {
      padding: 5px 0; }
    .custom-card .profile-details h6 {
      margin-bottom: 15px; }
    .custom-card .profile-details h4 {
      font-size: 20px; }
    .custom-card .card-profile img {
      height: 93px; }
  .card .card-header .card-header-right i {
    margin: 0 3px;
    font-size: 14px; }
  .blog-box.blog-shadow .blog-details {
    padding: 10px; }
    .blog-box.blog-shadow .blog-details h4 {
      font-size: 14px; }
  .cal-date-widget {
    padding: 30px; }
  .calender-widget .cal-img {
    height: 225px; }
  .company-card .details .media {
    flex-wrap: wrap; }
  .business-card .b-l-light {
    border-left: 1px solid transparent !important; }
  .stepwizard .stepwizard-row:before {
    top: 17px; }
  .stepwizard .stepwizard-row .stepwizard-step .btn {
    padding: 6px 22px; }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 83px;
    height: 83px; }
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px; }
    .mega-menu .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px; }
    .mega-menu .lg-mt {
      margin-top: 10px; }
    .mega-menu .xs-mt {
      margin-top: 15px; }
  .badge {
    padding: 0.42em 0.7em; }
  .alert-dismissible .close {
    top: -1px; }
    .alert-dismissible .close span {
      font-size: 19px; }
  .notify-alert {
    width: 90%; }
  .box-layout.page-wrapper .set-col-6, .box-layout.page-wrapper .set-col-3, .box-layout.page-wrapper .set-col-7, .box-layout.page-wrapper .set-col-5 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media only screen and (max-width: 420px) {
  .alert-dismissible {
    padding-right: 30px; } }

@media only screen and (max-width: 397px) {
  .clockpicker-align-top {
    top: 426px !important; } }

/* ========= responsive scss for 360 screen ========= */
@media only screen and (max-width: 360px) {
  .bar-chart-widget .earning-details i {
    right: -73px; }
  .widget-joins .border-after-xs:after {
    width: 92%; }
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    height: 45px;
    margin-top: 5px; }
  .knob-block canvas {
    width: 250px !important;
    height: 250px !important; }
  .knob-block .chart-clock-main .clock-large {
    left: -28px; }
    .knob-block .chart-clock-main .clock-large canvas {
      width: 250px !important;
      height: 250px !important; }
  .knob-block .chart-clock-main .clock-medium {
    left: 6px; }
    .knob-block .chart-clock-main .clock-medium canvas {
      width: 150px !important;
      height: 150px !important; }
  .knob-block .chart-clock-main .clock-small {
    left: 6px; }
    .knob-block .chart-clock-main .clock-small canvas {
      width: 50px !important;
      height: 50px !important; }
  .knob-chart {
    min-height: 220px; }
  .landing-main .landing-home .landing-left h1 {
    margin-bottom: 15px;
    font-size: 28px; }
  .landing-main .landing-home .landing-body img {
    width: 76%; }
  .landing-main .section-space .landing-header h2 {
    font-size: 18px; }
  .landing-main .section-space p {
    font-size: 14px; }
  .landing-main .particles-headline {
    padding-top: 95px; }
  .landing-main .landing-header h2 {
    font-size: 22px; }
  .landing-main .main-landing .landing-left {
    padding-left: 9px; }
    .landing-main .main-landing .landing-left .link-arrow a {
      left: 131px !important; }
  .landing-main .updates-features .inner-header {
    width: 84%; }
  .landing-main .key-features-section .feature-main .feature-content h5 {
    font-size: 16px; }
  .landing-main .exclusive-features .landing-header h2:after {
    width: 210px; }
  .landing-main .exclusive-features .exclusive-content h2 {
    font-size: 16px; }
  .landing-main .exclusive-features .exclusive-content p {
    width: 100%; }
  .landing-main .exclusive-features .exclusive-image .page-builder-top {
    top: 23px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-middle {
    left: -15px;
    bottom: 17px; }
  .landing-main .exclusive-features .exclusive-image .page-builder-bottom {
    bottom: -14px;
    right: 50px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-top {
    top: -13px;
    right: 76px; }
  .landing-main .exclusive-features .exclusive-image .form-builder-bottom {
    bottom: -49px;
    right: -22px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-left {
    left: 0px;
    bottom: 13px; }
  .landing-main .exclusive-features .exclusive-image .button-builder-middle {
    left: 118px;
    bottom: -21px; }
  .landing-main .exclusive-features .exclusive-image .fontawesome-right {
    bottom: -16px;
    right: 57px; }
  .customer-review .img-90 {
    width: 50px !important; }
  .custom-card .dashboard-card {
    padding-top: 0; }
  .custom-card .card-profile img {
    bottom: 25px; }
  .crm-numbers .col h4 {
    font-size: 15px; }
  .border-widgets > .row > div + div {
    border-left: none; }
    .border-widgets > .row > div + div + div {
      border-bottom: 1px solid #ddd; }
  .whether-widget .whether-widget-bottom svg {
    width: 60px;
    height: 60px; }
  .pricing-wrapper-card .pricing-card.popular .card-action h1 {
    font-size: 50px; }
  .pricing-wrapper-card .pricing-card.popular .card-action button {
    height: auto; }
  .pricing-wrapper-card .card-price {
    margin: 0 0; }
  .pricing-wrapper-card .card-description ul {
    margin-top: 20px;
    margin-bottom: 30px; }
  .flot-chart-container {
    height: 230px; }
  .map-block {
    height: 250px; }
  .img-cropper .docs-tooltip {
    font-size: 11px; }
  .img-cropper .docs-toggles .btn-group .btn {
    padding: 0.375rem 0.2rem; }
  .blog-box .blog-details p {
    line-height: 1.5; }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 10px; }
  .button-builder-wrap .btn-lg {
    font-size: 18px; }
  /* responsive material tabs*/
  .border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px 0px; }
  .tabs-responsive-side {
    max-width: 100%; }
  .cal-date-widget .datepicker {
    padding: 0;
    width: unset; }
  .top-sale-chart #myLineCharts {
    width: 100% !important; }
  .default-widget-count h3 {
    font-size: 20px; }
  .default-widget-count p {
    font-size: 15px; }
  .default-widget-count .left {
    width: 50px;
    height: 50px; }
    .default-widget-count .left i {
      font-size: 20px; }
  .default-widget-count .icon-bg {
    font-size: 40px; }
  .browser-widget .media i {
    font-size: 52px; }
  .browser-widget .media .media-body h4 {
    font-size: 20px; }
  .business-card table {
    margin-left: 0px; }
  .bar-chart-widget .bottom-content .num {
    font-size: 20px; }
  .stepwizard .stepwizard-row .stepwizard-step .btn {
    padding: 6px 20px; }
  .user-status table tbody img {
    height: 40px; }
  .social-media-counter i {
    font-size: 34px;
    padding: 7px 0; }
  .xs-width-100 {
    min-width: 100%; }
  .email-wrap .action-wrapper .actions li {
    margin-right: 13px; }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 65px;
    height: 65px; }
  .email-wrap .email-right-aside .email-top .dropdown-menu {
    left: -58px; }
  .form-builder #components .component .form-group #button1id {
    margin-bottom: 15px; }
  .mega-menu-p {
    padding: 0 23px !important; }
  .default-according .card .card-header i {
    top: 12px; }
  .default-according .card .btn-link {
    display: block;
    width: 100%;
    padding: 0; }
  .default-according.style-1 .card .btn-link {
    padding-left: 25px; }
  .star-ratings .stars .title {
    line-height: 1; }
  .user-profile .hovercard .social-media a {
    padding: 0 12px; }
  .datepicker-inline .datepicker {
    padding-left: 1px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 93% !important; }
  .step-container {
    height: 280px !important; }
  .sort-panel .d-inline .btn {
    margin-left: 87px; }
  .jsgrid .jsgrid-pager-container .jsgrid-pager .jsgrid-pager-page {
    padding: 0.5em 0.62em; }
  .chat-box .chat-right-aside {
    flex: 0 0 96%;
    max-width: 96%; }
    .chat-box .chat-right-aside .chat .chat-message {
      width: calc(100% - 20px); }
  .error-wrapper .error-heading .headline {
    font-size: 100px; }
  .lg-toolbar .lg-icon {
    width: 40px; }
  .calendar-wrap a.fc-more {
    font-size: .59em; }
  .calendar-wrap .fc th {
    font-size: 10px; } }

/**=====================
     63. Responsive CSS Ends
==========================**/
